import { gql } from '@apollo/client';
import { FRAGMENT_MY_GIFT } from '/@/api/graphql/internalApi/fragments/myGift.gql';

export const getMyGiftsConnectionGql = gql`
  ${FRAGMENT_MY_GIFT}
  query GetMyGiftsConnection(
    $sortConditions: [MyGiftSortConditionInputType!]
    $usageStatusIn: [MyGiftUsageStatusEnum!]
    $giftTypeIn: [MyGiftType_GiftTypeEnum!]
    $expiredAtLteq: ISO8601DateTime
    $expiredAtGteq: ISO8601DateTime
    $pointMergeableEq: Boolean
    $first: Int
    $after: String
  ) {
    myGiftsConnection(
      sortConditions: $sortConditions
      usageStatusIn: $usageStatusIn
      giftTypeIn: $giftTypeIn
      expiredAtLteq: $expiredAtLteq
      expiredAtGteq: $expiredAtGteq
      pointMergeableEq: $pointMergeableEq
      first: $first
      after: $after
    ) {
      nodes {
        ...MyGiftFields
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
