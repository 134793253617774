import { gql } from '@apollo/client';

export const getAnnouncementsConnectionGql = gql`
  query GetAnnouncementsConnection($after: String, $first: Int) {
    userAnnouncements(after: $after, first: $first) {
      nodes {
        uid
        title
        createdAt
        readAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
